import React, { useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';




const Footer = () => {
  return (
    <Wrapper>
      <Grid container gap={2} className="UpperFooter">
        <Grid item lg={4} xs={11}>
          <div className="ActionHolder">
            <p className="ReadyText">Ready to get started?</p>
            <div className="ActionButton">
                    <p className="ButtonText">Sign Up</p>
            </div>
          </div>
        </Grid>
       
        <Grid item lg={2} xs={2.5}>
          <div className="Holder">
            <p className="TitleText">Services</p>
            <p className="SubTitleText">Campaigns</p>
            
          </div>
        </Grid>
        <Grid item lg={2}  xs={2.5}>
        <div className="Holder">
            <p className="TitleText">About</p>
            <p className="SubTitleText">Campaigns</p>
            
          </div>
        </Grid>
        <Grid item lg={2}  xs={2.5}>
        <div className="Holder">
            <p className="TitleText">Help</p>
            <p className="SubTitleText">Campaigns</p>
            
          </div>
        </Grid>
      </Grid>
      

      <Grid container gap={2} className="LowerFooter">
        <Grid item lg={5} xs={12}>
          <div className="PolicyHolder">

                <p className="SubTitleText">Terms & Conditions</p>
                <p className="SubTitleText">Privacy Policy</p>
          </div>
        </Grid>
        <Grid item lg={5} xs={12}>
          <div className="IconHolder">
            <FacebookIcon className="Icon"/>
            <InstagramIcon className="Icon"/>
            <TwitterIcon className="Icon"/>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  background-color: black;
  padding:2%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  .UpperFooter{
    display:flex;
    justify-content:center;
  }
  .ReadyText{
    font-size:35px;
    font-weight:100;
    color:white;
  }
  .TitleText{
    font-size:25px;
    font-weight:100;
    color:white;
  }
  .SubTitleText{
    font-size:20px;
    font-weight:100;
    color:rgb(255,255,255,0.4);
  }
  .Holder{
    margin-top:45px;
  }
  .ActionButton{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    font-size:22.5px;
    font-weight:100;
    height:65px;
    border-radius:35px;
    width:150px;
    color:white;
    background-color:rgba(113, 198, 217, 0.45);
    transition: all 0.5s ease;
  }
  .ActionButton:hover{
        background-color:white;
        color:black;
        cursor:pointer;
        scale:1.08;
  }
  .PolicyHolder{
    display:flex;
    justify-content:space-evenly;
    color:rgb(255,255,255,0.4);
  }
  .LowerFooter{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding-top:5%;
}
  .Icon{
    color:white;
    font-size:50px;
  }
  .Icon:hover{
    cursor:pointer;
  }
  .IconHolder{
    display:flex;
    justify-content:space-evenly;
  }
  @media (max-width: 767px) {
    padding:4%;
    .ReadyText{
      font-size:20px;
    }
    
   
    
    .ActionButton{
      font-size:18px;
      height:50px;
      border-radius:35px;
      width:100px;
    }
    .TitleText{
      font-size:18px;
      font-weight:100;
      color:white;
      text-align:center;
    }
    .SubTitleText{
      font-size:15px;
      font-weight:100;
      color:rgb(255,255,255,0.4);
    }
  }
`;
export default Footer;
