import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './Pages/Homepage';
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Hero/>} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
