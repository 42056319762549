import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Pagination, Navigation, Zoom } from "swiper/modules";

const Form = () => {
  const [swiper, setSwiper] = useState(null);
  const wrapperRef = useRef(null);
  const handleKeyDown = (event) => {
    if (swiper) {
      if (event.key === "ArrowLeft" || event.key == "ArrowUp") {
        swiper.slidePrev();
      } else if (event.key === "ArrowRight" || event.key == "ArrowDown") {
        console.log("Right");
        swiper.slideNext();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [swiper]);

  const [myValue, setMyValue] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMyValue(false);
      if (wrapperRef.current) {
        wrapperRef.current.style.backgroundColor = "black"; // Directly set the background color of the Wrapper
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };
  return (
    <Wrapper ref={wrapperRef}>

      {myValue ? (
        <>
          <div className="LoaderScreen">
            <img src="/Picture3.jpg" className="ImageBackground" />
            <motion.img
              src="/Picture4.png"
              className="LoaderImage"
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 2, loop: Infinity, ease: "linear" }}
            />
          </div>
        </>
      ) : (
        <>
          <Swiper
            spaceBetween={15}
            pagination={pagination}
            zoom={true}
            modules={[Pagination, Navigation, Zoom]}
            navigation={{
              nextEl: '.swiper-button-next', // Custom class for "Next" button
              prevEl: '.swiper-button-prev', // Custom class for "Previous" button
            }}
            onSwiper={(s) => setSwiper(s)}
            className="mySwiper"
          >
            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page1.png" className="PageOut" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page2.png" className="PageOut" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page3.png" className="PageOut" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page4.png" className="PageOut" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page5.png" className="PageOut" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page6.png" className="PageOut" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page7.png" className="PageOut" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src="/Page8.png" className="PageOut" />
              </div>
            </SwiperSlide>
          </Swiper>
        </>


      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100%;
  font-family: "Century Gothic", sans-serif;
  overflow-x: hidden;
  overflow: hidden;
  background-color: transparent;
  .PageOut{
    height:100vh;
    width:100%;
    object-fit: contain;
  }

  .Number{
    position:absolute;
    right:5%;
    top:5%;
    background-color:rgb(0,0,0,0.20);
    height:40px;
    width:40px;
    display:flex;
    justify-content:center;
    padding:0.5%;
    align-items:center;
    color:white;
    font-weight:1000;
    font-size:40px;
    border-radius:7px;
  }

    .ImageBackground {
      position: absolute;
      top: 0px;
      z-index: -10;
      height: 100%;
      width: 100%;
    }
    
  
    .LoaderScreen {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
      
    
    .LoaderImage {
      height: 150px;
      margin-bottom:5%;
    }


    .swiper-pagination-bullet {
      width: 27px;
      height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5%;
      line-height: 20px;
      font-size: 12px;
      color: #000;
      border-radius: 50%;
      opacity: 1;
      background: rgba(0, 0, 0, 0.8);
      padding: 4px;
      z-index: 1;
    }

    
    .swiper-pagination-bullet-active {
      color: #fff;
      background: #007aff;
    }

    
    .mySwiper {
      height: 100vh;
    }

    
    .ImageBackground {
      z-index: -1;
      position: absolute;
    }
  
    .Image {
      height: 100%;
      width: 100%;
    }


    .swiper-button-prev {
      position: absolute;
      left: 10px; 
      top: 50%; 
      transform: translateY(-50%);
      width: 30px; 
      height: 30px; 
      background-color: rgba(0, 0, 0, 0.5); 
      border-radius: 50%; 
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1000; 
    }
    .swiper-button-next {
      position: absolute;
      right: 10px;
      top: 50%; 
      transform: translateY(-50%);
      width: 30px; 
      height: 30px; 
      background-color: rgba(0, 0, 0, 0.5); 
      border-radius: 50%; 
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1000; 
    }


    @media (min-height: 500px) and (max-height: 600px){
      
      .LoaderScreen {
          height: 100vh;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

    }

  @media (min-height: 601px) {
    
    .LoaderScreen {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

  }
    

  @media (min-width: 300px) and (max-width: 767px){

      .mySwiper {
        height: 100vh
      }

      .swiper-pagination {
        position: absolute;
        bottom: 70px; 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        margin: 10px 5px; /* Adjust the top and bottom margin to increase the vertical gap */
        background: white;
        border-radius: 50%;
        opacity: 1;
        transition: background 0.3s ease-in-out;
        cursor: pointer;
      }

      .swiper-pagination-bullet-active {
        background: #007aff;
      }

      .swiper-button-prev {
         display: none;
        }
      .swiper-button-next {
        display: none;
      }

      .LoaderImage {
        height: 150px;
        margin-bottom:0%;
      }

      
    }

    @media (min-width: 767px){
      .mySwiper {
        height: 100vh
      }

      .swiper-pagination {
        position: absolute;
        top: 20%; 
        display: grid;
        justify-content: start;
        align-items: center;
        width: 100%;
        height: 500px;
        padding-left: 0.5rem;
      }

      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        margin: 10px 5px; /* Adjust the top and bottom margin to increase the vertical gap */
        background: white;
        border-radius: 50%;
        opacity: 1;
        transition: background 0.3s ease-in-out;
        cursor: pointer;
      }

      .swiper-pagination-bullet-active {
        background: #007aff;
      }

    }

    @media (max-width: 767px){
      @supports (-webkit-overflow-scrolling: touch) {
      overflow-y: hidden;


      .mySwiper {
        height: 100vh
      }

      
      .PageOut{
        height:40vh;
        width:100%;
        margin-top: 40%;
        object-fit: fill;
      }


      .swiper-pagination {
        position: absolute;
        bottom: 130px; 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        margin: 10px 5px; /* Adjust the top and bottom margin to increase the vertical gap */
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        opacity: 1;
        transition: background 0.3s ease-in-out;
        cursor: pointer;
      }

      .swiper-pagination-bullet-active {
        background: #007aff;
      }
    }
    }

`;
export default Form;
